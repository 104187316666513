import unionBy from 'lodash/unionBy';

import type { SpacePageCard } from '@confluence/page-card';
import { transformSpaceContent } from '@confluence/page-card';
import {
	getEmojiPublishedFromProperties,
	getEmojiDraftFromProperties,
} from '@confluence/emoji-title/entry-points/transformer';
import { getTitleAndEmoji } from '@confluence/emoji-title/entry-points/helpers';

import type {
	SpacePagesQuery as SpacePagesQueryType,
	SpacePagesQuery_confluenceContentSearch_nodes as SearchResult,
	SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult as ContentSearchResult,
} from './__types__/SpacePagesQuery';

export const isContentSearchResult = (
	searchResult: SearchResult,
): searchResult is ContentSearchResult => Boolean((searchResult as ContentSearchResult).id);

const hasBraidedContent = (item) => Boolean(item?.content);

const isNotBlankDraft = (item) => !Boolean(item.content?.status === 'draft' && item.content?.blank);

export const transformSearchResult = (
	searchResult: ContentSearchResult,
	untitledDraftText: string,
	isWhiteboardEmojiFFEnabled = false,
): SpacePageCard => {
	const EMOJI_CONTENT_TYPE = isWhiteboardEmojiFFEnabled
		? ['page', 'blogpost', 'embed', 'whiteboard']
		: ['page', 'blogpost', 'embed'];

	return {
		...transformSpaceContent(searchResult.content),
		// @ts-expect-error TODO FIXME some deep property null checks are failing, worth looking into
		title: getTitleAndEmoji(
			searchResult?.title,
			// @ts-expect-error TODO FIXME some deep property null checks are failing, worth looking into
			getEmojiPublishedFromProperties(searchResult.content),
			// @ts-expect-error TODO FIXME some deep property null checks are failing, worth looking into
			getEmojiDraftFromProperties(searchResult.content),
			searchResult?.content?.status || undefined,
			untitledDraftText,
			// @ts-expect-error TODO FIXME some deep property null checks are failing, worth looking into
			EMOJI_CONTENT_TYPE.includes(searchResult?.content?.type),
		),
		lastAction: {
			when: searchResult.lastModified.value,
			action: 'updated',
		},
		links: {
			editui: searchResult.content?.links?.editui || '',
			webui: searchResult.url,
		},
		excerpt: searchResult.excerpt.trim(),
	};
};

export const transformSearchData = (
	data: SpacePagesQueryType | undefined,
	untitledDraftText: string,
	isWhiteboardEmojiFFEnabled = false,
	isShowingBlankDraftsEnabled = false,
): SpacePageCard[] =>
	(data?.confluenceContentSearch.nodes || [])
		.filter(isContentSearchResult)
		.filter(hasBraidedContent)
		.filter((node) => isShowingBlankDraftsEnabled || isNotBlankDraft(node))
		.map((node) => transformSearchResult(node, untitledDraftText, isWhiteboardEmojiFFEnabled));

export const transformRecommendedData = (
	data: SpacePagesQueryType | undefined,
	untitledDraftText: string,
	isShowingBlankDraftsEnabled = false,
): SpacePageCard[] =>
	(data?.objectRecommendations?.nodes || [])
		.filter(hasBraidedContent)
		.filter((node) => isShowingBlankDraftsEnabled || isNotBlankDraft(node))
		.map((node) => transformSpaceContent(node.content, untitledDraftText));

export const transformData = (
	data: SpacePagesQueryType | undefined,
	untitledDraftText: string,
	isWhiteboardEmojiFFEnabled = false,
	isShowingBlankDraftsEnabled = false,
): SpacePageCard[] => {
	const search = transformSearchData(
		data,
		untitledDraftText,
		isWhiteboardEmojiFFEnabled,
		isShowingBlankDraftsEnabled,
	);
	const recommended = transformRecommendedData(
		data,
		untitledDraftText,
		isShowingBlankDraftsEnabled,
	);
	return unionBy(recommended, search, (page) => page.id);
};
