import { useCallback } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

type PayloadType = {
	data: { [key: string]: any };
	[key: string]: any;
};

export const useSpacePagesAnalyticsEvents = (spaceId?: string) => {
	const { createAnalyticsEvent: akCreateAnalyticsEvent } = useAnalyticsEvents();
	const createAnalyticsEvent = useCallback(
		(payload: PayloadType) => {
			payload.data.containerId = spaceId;
			payload.data.source = 'spacePagesScreen';
			return akCreateAnalyticsEvent(payload);
		},
		[spaceId, akCreateAnalyticsEvent],
	);
	return { createAnalyticsEvent };
};
