import type { FC } from 'react';
import React, { useContext } from 'react';
import { styled } from '@compiled/react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import Button from '@atlaskit/button/standard-button';

import { PagesFilter, SpacePagesContext } from '../SpacePagesContext';

import NoResultsImage from './assets/NoResultsImage.svg';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NoResultsContainer = styled.div({
	marginTop: token('space.1000', '80px'),
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NoResultsHeader = styled.h2({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '50px',
	fontSize: token('space.200', '16px'),
	lineHeight: token('space.250', '20px'),
	letterSpacing: '-0.006em',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NoResultsBody = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '26px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ClearFilterButton = styled.button({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '34px',
});

const i18n = defineMessages({
	noResultsHeader: {
		id: 'space-pages.search.results.no.results.header',
		defaultMessage: 'Nothing matches what you are looking for',
		description:
			"Descriptive header that appears when filters are applied to a space's Pages screen but no results are found.",
	},
	noResultsBody: {
		id: 'space-pages.search.results.no.results.body',
		defaultMessage: 'Remove any filters, or search for something less specific.',
		description:
			"Descriptive body text that appears when filters are applied to a space's Pages screen but no results are found.",
	},
	resetFilter: {
		id: 'space-pages.search.results.no.results.reset.filter',
		defaultMessage: 'Reset',
		description:
			"Text for button that appears when filters are applied to a space's Pages screen but no results are found. The button clears any active filters applied to the Pages view.",
	},
});

export const NoResults: FC = () => {
	const { setFilter } = useContext(SpacePagesContext);
	return (
		<NoResultsContainer>
			<img src={NoResultsImage} alt="" />
			<NoResultsHeader>
				<FormattedMessage {...i18n.noResultsHeader} />
			</NoResultsHeader>
			<NoResultsBody>
				<FormattedMessage {...i18n.noResultsBody} />
			</NoResultsBody>
			<Button
				onClick={() => setFilter({ text: '', pagesFilter: PagesFilter.all })}
				component={ClearFilterButton}
			>
				<FormattedMessage {...i18n.resetFilter} />
			</Button>
		</NoResultsContainer>
	);
};
