/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum PagesDisplayPersistenceOption {
  CARDS = "CARDS",
  COMPACT_LIST = "COMPACT_LIST",
  LIST = "LIST",
}

export enum PagesSortField {
  LAST_MODIFIED_DATE = "LAST_MODIFIED_DATE",
  RELEVANT = "RELEVANT",
  TITLE = "TITLE",
}

export enum PagesSortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SpacePagesPersistenceQuery
// ====================================================

export interface SpacePagesPersistenceQuery_userPreferences_spacePagesSortView {
  field: PagesSortField;
  order: PagesSortOrder;
}

export interface SpacePagesPersistenceQuery_userPreferences {
  globalPageCardAppearancePreference: PagesDisplayPersistenceOption;
  spacePagesSortView: SpacePagesPersistenceQuery_userPreferences_spacePagesSortView;
}

export interface SpacePagesPersistenceQuery {
  userPreferences: SpacePagesPersistenceQuery_userPreferences;
}

export interface SpacePagesPersistenceQueryVariables {
  spaceKey: string;
}
